import React, { Component } from 'react';
import { Table, Tag, Divider, Tooltip } from 'antd';
import NumberFormat from 'react-number-format';
import { ConvertCashReceiptStatus, ConvertPaymentMethod, isValidDate, stripHtml } from '../utils/helpers';
import TextTruncate from 'react-text-truncate';
import HtmlParser from 'react-html-parser';

class PaymentHistory extends Component {

    columns = [
        {
            title: 'STT',
            key: 'index',
            width: '40px',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Mã phiếu',
            dataIndex: 'code',
            key: 'code',
            width: '80px'
        },
        {
            title: 'Ngày TT',
            dataIndex: 'payment_at',
            key: 'payment_at',
            render: (text, record) => {
                if (text && text !== '') {
                    return (
                        <>{isValidDate(text, false)}</>
                    )
                } else {
                    return (<Tag></Tag>)
                }
            }
        },
        {
            title: 'Tổng cộng',
            dataIndex: 'total_amount',
            key: 'total_amount',
            render: (text, record) => (
                <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
            )
        },
        {
            title: 'Ưu đãi',
            dataIndex: 'special_discount',
            key: 'special_discount',
            render: (text, record) => (
                <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
            )
        },
        {
            title: 'Doanh số',
            dataIndex: 'adjusted_sales',
            key: 'adjusted_sales',
            render: (text, record) => (
                <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
            )
        },
        {
            title: 'Số tiền TT',
            dataIndex: 'amount_paid',
            key: 'amount_paid',
            render: (text) => (
                <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
            ),
        },
        {
            title: 'Tổng đã TT',
            dataIndex: 'total_amount_paid',
            key: 'total_amount_paid',
            render: (text) => (
                <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
            ),
        },
        {
            title: 'Công nợ',
            dataIndex: 'remaining_debt',
            key: 'remaining_debt',
            render: (text) => (
                <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
            ),
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            width: '120px',
            render: (text, record) => {
                return (
                    <>{ConvertCashReceiptStatus(text)}</>
                )
            }
        },
        {
            title: 'Phương thức TT',
            dataIndex: 'payment_method',
            key: 'payment_method',
            render: (text, record) => {
                return (
                    <>{ConvertPaymentMethod(text)}</>
                )
            }
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
            width: '200px',
            render: (text, record) => {
                return (
                    <div style={{ wordBreak: "break-all" }}>
                        <Tooltip title={HtmlParser(text)} placement="topLeft">
                            <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                        </Tooltip>
                    </div>
                )
            }
        }
    ];


    render() {

        return (
            <>
                {/* {
                    this.props.area != 'customer-list' ? (
                        <Divider orientation="left" orientationMargin="0" className='mt-4 mb-3'>
                            <Tag color='#0b2b33'>11</Tag> Lịch sử thanh toán
                        </Divider>
                    ) : null
                } */}

                <Table
                    size='small'
                    columns={this.columns}
                    dataSource={this.props.data ?? []}
                    pagination={false}
                    locale={{ emptyText: "Chưa có dữ liệu" }}
                    scroll={{
                        x: 'max-content'
                    }}
                />
            </>
        );
    }
}

export default PaymentHistory;
