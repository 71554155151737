import React, { Component } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
//const { Option } = Select;

export default class BaseSelect extends Component {
    static propTypes = {
        defaultText: PropTypes.string,
        options: PropTypes.array.isRequired,
        attr: PropTypes.object,
        onChange: PropTypes.func,
        onScrollEnd: PropTypes.func,
    };

    static defaultProps = {
        optionValue: "value",
        optionLabel: "label",
        onScrollEnd: () => { }
    };

    handleScroll(e) {
        this.props.onScrollEnd();
    }

    render() {
        var {
            defaultText,
            selected,
            options,
            mode,
            isloading,
            attr,
            optionValue, // name of value field
            optionLabel, // name of label field
            additionalLabel1,
            additionalLabel2,
            additionalLabel3,
            onChange,
            onScrollEnd,
            ...rest
        } = this.props;

        let value = selected ? selected : "";

        if (options.length) {
            let temp = options.find(option => option[optionValue] == selected);
            if (!temp) value = "";

            if (!defaultText) {
                value = options[0][optionValue];
            }
        }

        return (
            <Select
                defaultValue={value}
                mode={mode}
                allowClear
                loading={isloading}
                {...rest}
                onChange={(value) => onChange(value)}
                filterOption={(input, option) =>
                    option?.title?.toLowerCase().includes(input.toLowerCase())
                }
                onPopupScroll={(e) => this.handleScroll(e)}
                options={[
                    ...(defaultText ? [{ value: null, label: defaultText }] : []),
                    ...options.map((option, index) => {
                        const addLabel1 = option[additionalLabel1] ?? 0;
                        const addLabel2 = option[additionalLabel2] ?? "-";
                        const addLabel3 = option[additionalLabel3] ? `, ${option[additionalLabel3]}` : "Kho: ";

                        let hh = "";
                        if (additionalLabel1 || additionalLabel2 || additionalLabel3) {
                            hh = `(Số lượng: ${addLabel1}, Hạn sử dụng: ${addLabel2}${addLabel3})`;
                        }

                        const labelText = `${option[optionLabel]} ${hh}`;

                        return {
                            key: `${option[optionValue]}_${index}`,
                            value: option[optionValue],
                            label: (
                                <span>
                                    <span style={{ fontWeight: "bold" }}>{option[optionLabel]}</span>
                                    <span style={{ fontStyle: "italic" }}> {hh}</span>
                                </span>
                            ),
                            title: labelText,
                        };
                    }),
                ]}
            />

            // <Select
            //     defaultValue={value}
            //     mode={mode}
            //     allowClear
            //     loading={isloading}
            //     {...rest}
            //     onChange={(value) => onChange(value)}
            //     filterOption={(input, option) => {
            //         const children = option?.children?.props?.children
            //             .filter(item => item.props !== undefined)
            //             .map(value => value.props.children)[0];
            //         return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            //     }}
            //     onPopupScroll={(e) => this.handleScroll(e)}
            // >
            //     {
            //         defaultText ? (
            //             <Option value={null}>{defaultText}</Option>
            //         ) : null
            //     }
            //     {
            //         options.map((option, index) => {
            //             let label;
            //             if (additionalLabel1 || additionalLabel2 || additionalLabel3) {
            //                 const addLabel1 = option[additionalLabel1] && option[additionalLabel1] !== null ? option[additionalLabel1] : 0;
            //                 const addLabel2 = option[additionalLabel2] && option[additionalLabel2] !== null ? option[additionalLabel2] : '-';
            //                 const addLabel3 = option[additionalLabel3] && option[additionalLabel3] !== null ? ", " + option[additionalLabel3] : "Kho: ";
            //                 let hh;
            //                 if (addLabel1 || addLabel2 || addLabel3) {
            //                     hh = "(Số lượng: " + addLabel1 + ", Hạn sử dụng: " + addLabel2 + addLabel3 + ")"
            //                 }
            //                 label = (
            //                     <span>
            //                         <span style={{ fontWeight: 'bold' }}>{option[optionLabel]}</span> <span style={{ fontStyle: 'italic' }}> {hh}</span>
            //                     </span>
            //                 );
            //             } else {
            //                 label = <span style={{ fontWeight: 'bold' }}>{option[optionLabel]}</span>;
            //             }

            //             const labelText = `${option[optionLabel]} ${additionalLabel1 || additionalLabel2 || additionalLabel3 ? `(Số lượng: ${option[additionalLabel1] || 0}, Hạn sử dụng: ${option[additionalLabel2] || '-'}${option[additionalLabel3] ? ", " + option[additionalLabel3] : "Kho: "})` : ''}`;

            //             return (
            //                 <Option title={labelText} key={`${option[optionValue]}_${index}`} value={option[optionValue]}>{label}</Option>
            //             );
            //         })
            //     }
            // </Select>
        )
    }
}