import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Tag, Row, Col, Card, Typography, Button, Descriptions, Modal, Spin } from 'antd'

import { voucherCustomerGetUsedByCustomer, voucherCustomerGetUsed, voucherCustomerRemove } from '../../redux/actions/VoucherActions';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import VoucherApplyCustomerForm from './VoucherApplyCustomerForm';
import { isValidDate } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';

const { Text } = Typography;
const { confirm } = Modal;

class VoucherCustomerDetail extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            currentItem: [],
            isOpenForm: false,
            currentCustomer: null,
            currentData: null,
        };
    }

    componentDidMount() {
        this.loadData();
    }

    openForm() {
        this.setState({ currentCustomer: this.props.currentCustomer, isOpenForm: true })
    }

    onCancel() {
        this.setState({ isOpenForm: false, currentData: null })
    }

    loadData() {
        this.setState({ isLoading: true })
        this.props.voucherCustomerGetUsedByCustomer(this.props.currentCustomer?.id).then(res => {
            this.setState({ currentItem: res, isLoading: false })
        }).catch(err => {
            this.setState({ isLoading: false })
        });
    }

    openRemove(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.voucherCustomerRemove(id).then(() => {
                setTimeout(() => {
                    this.loadData();
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    onEdit(id) {
        this.props.voucherCustomerGetUsed(id).then(res => {
            this.setState({
                currentCustomer: this.props.currentCustomer,
                currentData: res,
                isOpenForm: true
            })
        })
    }

    render() {
        var { currentItem, currentData, isOpenForm, currentCustomer, isLoading } = this.state;

        return (
            <>
                {/* <Divider orientation="left" orientationMargin="0" className='mb-0'>
                    <Tag color='#0b2b33'>12</Tag> Voucher
                </Divider> */}
                <Row justify="end" className='mb-1'>
                    <Button
                        disabled={!checkPermission('voucher', 'apply_customer')}
                        type="dashed" size='small'
                        className='color-blue'
                        onClick={() => this.openForm()}
                        icon={<PlusOutlined />}>
                        Thêm mới
                    </Button>
                </Row>
                {
                    currentItem && currentItem?.length ? (
                        <Spin tip="Loading..." spinning={isLoading}>
                            <Row gutter={16} className='mb-3 row-voucher-customer-detail'>
                                {
                                    currentItem.map(item => {
                                        return (
                                            <Col xs={24} md={12} className='mb-3'>
                                                <Card size="small"
                                                    className='card-voucher-detail'
                                                    extra={
                                                        <>
                                                            {checkPermission('voucher', 'customer_update') ? (
                                                                <a onClick={() => this.onEdit(item.used_id)} type="link" key="list-vertical-edit">
                                                                    <EditOutlined /> Sửa
                                                                </a>
                                                            ) : null}
                                                            <Divider type='vertical' />
                                                            {checkPermission('voucher', 'voucher_remove') ? (
                                                                <span className='delete-icon' onClick={() => this.openRemove(item.used_id)} type="link" key="list-vertical-delete">
                                                                    <DeleteOutlined /> Xóa
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    }
                                                >
                                                    <Col xs={24} md={24}>
                                                        <h6>{item.name}</h6>
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Mã">
                                                                {item.code}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Discount">
                                                                {item.discount}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Ngày sử dụng">
                                                                {isValidDate(item.used_date, false)}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Text strong>Ghi chú: </Text>
                                                        {HtmlParser(item.used_note)}
                                                    </Col>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Spin>
                    ) : <Row gutter={16}><div style={{ textAlign: 'center', width: '100%' }} className='no-data'>Chưa có dữ liệu</div></Row>
                }
                <VoucherApplyCustomerForm
                    visible={isOpenForm}
                    currentCustomer={currentCustomer}
                    currentItem={currentData}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.onCancel()}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        voucherCustomerGetUsedByCustomer: (customer_id) => dispatch(voucherCustomerGetUsedByCustomer(customer_id)),
        voucherCustomerRemove: (id) => dispatch(voucherCustomerRemove(id)),
        voucherCustomerGetUsed: (id) => dispatch(voucherCustomerGetUsed(id))
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(VoucherCustomerDetail);

// Sử dụng forwardRef để truyền ref xuống component
// Mục đích để cho parent component có thể gọi method của child component
const ConnectedVoucherCustomerDetail = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(VoucherCustomerDetail);

export default React.forwardRef((props, ref) => (
    <ConnectedVoucherCustomerDetail {...props} ref={ref} />
));
