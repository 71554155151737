import React, { Component } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { repeatPhoneNumber } from '../../../utils/helpers';
import { checkPermission } from '../../../utils/permission';
//const { Option } = Select;

export default class BaseSelect extends Component {
    static propTypes = {
        defaultText: PropTypes.string,
        options: PropTypes.array.isRequired,
        attr: PropTypes.object,
        onChange: PropTypes.func,
        onScrollEnd: PropTypes.func,
    };

    static defaultProps = {
        optionValue: "value",
        optionLabel: "label",
        onScrollEnd: () => { }
    };

    handleScroll(e) {
        this.props.onScrollEnd();
    }

    render() {
        var {
            defaultText,
            selected,
            options,
            mode,
            isloading,
            attr,
            optionValue, // name of value field
            optionLabel, // name of label field
            additionalLabel,
            additionalLabel2,
            isCustomerList,
            onChange,
            onScrollEnd,
            ...rest
        } = this.props;

        let value = selected ? selected : "";

        if (options.length) {
            let temp = options.find(option => option[optionValue] == selected);
            if (!temp) value = null;

            if (!defaultText) {
                value = options[0][optionValue];
            }
        }

        return (
            <Select
                defaultValue={value}
                mode={mode} // multiple
                allowClear
                loading={isloading}
                {...rest}
                onChange={(value, option) => {
                    const label = option ? option.label : null; // Dùng option.label thay vì option.children
                    onChange(value, label);
                }}
                filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                }
                onPopupScroll={(e) => this.handleScroll(e)}
                options={[
                    ...(defaultText ? [{ value: null, label: defaultText }] : []),
                    ...options.map((option, index) => {
                        let label = option[optionLabel];
                        let additionalInfo = "";

                        if (additionalLabel) {
                            additionalInfo += option[additionalLabel];
                            if (additionalLabel2) {
                                additionalInfo = `${option[additionalLabel2]}, ${additionalInfo}`;
                            }
                        }

                        if (isCustomerList && additionalLabel === "phone") {
                            if (checkPermission("customer", "phonenumber")) {
                                additionalInfo = `${option[additionalLabel2]}, ${option[additionalLabel]}`;
                            } else {
                                additionalInfo = `${option[additionalLabel2]}, ${repeatPhoneNumber(option[additionalLabel])}`;
                            }
                        }

                        label += additionalInfo ? ` (${additionalInfo})` : "";

                        return {
                            key: `${option[optionValue]}_${index}`,
                            value: option[optionValue],
                            label: label,
                        };
                    }),
                ]}
            />

            // <Select
            //     defaultValue={value}
            //     mode={mode} //multiple 
            //     allowClear
            //     loading={isloading}
            //     {...rest}
            //     onChange={(value, option) => {
            //         const label = option ? option.label : null; // Lấy label từ Option
            //         onChange(value, label); // Gọi hàm onChange với value và label
            //     }}
            //     filterOption={(input, option) =>
            //         option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            //     }

            //     onPopupScroll={(e) => this.handleScroll(e)}
            // >
            //     {
            //         defaultText ? (
            //             <Option value={null}>{defaultText}</Option>
            //         ) : null
            //     }
            //     {
            //         options.map((option, index) => {
            //             let label = option[optionLabel];
            //             let additionalInfo = '';

            //             if (additionalLabel) {
            //                 additionalInfo += option[additionalLabel];

            //                 if (additionalLabel2) {
            //                     additionalInfo = `${option[additionalLabel2]}, ${additionalInfo}`;
            //                 }
            //             }

            //             if (isCustomerList && additionalLabel === 'phone') {
            //                 if (checkPermission('customer', 'phonenumber')) {
            //                     additionalInfo = `${option[additionalLabel2]}, ${option[additionalLabel]}`;
            //                 } else {
            //                     additionalInfo = `${option[additionalLabel2]}, ${repeatPhoneNumber(option[additionalLabel])}`;
            //                 }
            //             }

            //             label += additionalInfo ? ` (${additionalInfo})` : '';

            //             return (
            //                 <Option key={`${option[optionValue]}_${index}`} value={option[optionValue]} label={label}>{label}</Option>
            //             );
            //         })

            //     }
            // </Select>
        )
    }
}