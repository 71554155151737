import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Checkbox, Alert, Button, Spin, Divider, Timeline, Select } from 'antd';
import BaseCheckboxes from '../../components/Elements/BaseCheckboxes';
import BaseSelect from '../../components/Elements/BaseSelect';
import { chunkGroup } from '../../utils/permission';
import _ from 'lodash';
import IntlMessages from '../../components/IntlMessage';
import { NotificationManager } from 'react-notifications';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
// actions
import { getAllPermissions, updateRolePermissions, getPermissionsByRole, getPermissionsByUser } from '../../redux/actions/PermissionActions';
import { getAllUsers } from '../../redux/actions/UserActions';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/permissions',
        breadcrumbName: 'Phân quyền',
    },
]

const PermissionGroup = ({ groups, permissionGroup, onCheckAll, onCheck }) => (
    <React.Fragment>
        {
            groups.map((groupChunk, index) => {
                return (
                    <Row gutter={20} style={{ height: 'max-content' }} key={index}>
                        {
                            groupChunk.map((group, index) => {
                                let groupName = group[0].route_name.split('.')[1];
                                let groupChecked = false;
                                if (permissionGroup[groupName]) {
                                    if (permissionGroup[groupName].length === group.length) groupChecked = true;
                                }

                                //console.log(groupChecked);
                                return (
                                    <Col lg={6} md={6} sm={24} xs={24} key={index} className="mb-4" >
                                        <Card
                                            title={<IntlMessages id={`admin.${groupName}`} />}
                                            size="small"
                                            extra={
                                                <Checkbox
                                                    onChange={(e) => onCheckAll(e, groupName, group)}
                                                    defaultChecked={groupChecked}
                                                ></Checkbox>
                                            }
                                            style={{ marginBottom: '20px', height: '100%' }}
                                        >
                                            <BaseCheckboxes
                                                options={group}
                                                optionLabel="name"
                                                optionValue="id"
                                                vertical
                                                onChange={(checkedValues) => onCheck(checkedValues, groupName)}
                                                value={permissionGroup[groupName]}
                                                intl={true}
                                            />
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                )
            })
        }
    </React.Fragment>
)

function handlePermissions(permissions) {
    var permissionGroup = {};
    permissions.forEach(permission => {
        let groupName = permission.route_name.split('.')[1];
        if (!permissionGroup[groupName]) {
            permissionGroup[groupName] = [permission.id];
        } else {
            permissionGroup[groupName] = [...permissionGroup[groupName], permission.id];
        }
    })

    return permissionGroup;
}

class UserPermission extends Component {
    state = {
        permissionGroup: {},
        isLoading: false,
        currentRole: '',
        currentUser: '',
        roles: []
    }

    componentDidMount() {
        this.props.getAllPermissions();

    }

    onChangeRole(value) {
        this.setState({ currentRole: value, isLoading: true }, () => {
            this.props.getAllUsers({ role: value })
            this.props.getPermissionsByRole(this.state.currentRole).then(permissions => {
                var permissionGroup = handlePermissions(permissions);

                this.setState({ permissionGroup: permissionGroup, currentUser: '', isLoading: false });
            })
        })
    }

    onChangeUser(user) {
        if (user) {
            this.setState({ currentUser: user, isLoading: true }, () => {
                this.props.getPermissionsByUser(this.state.currentUser).then(permissions => {
                    var permissionGroup = handlePermissions(permissions);

                    this.setState({ permissionGroup: permissionGroup, isLoading: false });
                })
            })
        } else {
            this.props.getPermissionsByRole(this.state.currentRole).then(permissions => {
                var permissionGroup = handlePermissions(permissions);

                this.setState({ permissionGroup: permissionGroup, currentUser: '', isLoading: false });
            })
        }
    }

    onCheck = (checkedValues, groupName) => {
        this.setState({
            permissionGroup: {
                ...this.state.permissionGroup,
                [groupName]: checkedValues
            }
        })
    }

    onCheckAll = (e, groupName, group) => {
        let values = group.map(item => item.id)
        if (e.target.checked) {
            this.setState({
                permissionGroup: {
                    ...this.state.permissionGroup,
                    [groupName]: values
                }
            })
        } else {
            this.setState({
                permissionGroup: {
                    ...this.state.permissionGroup,
                    [groupName]: []
                }
            })
        }
    }

    submit() {
        if (!this.state.currentRole) {
            NotificationManager.error("Bạn chưa chọn vai trò!")
        } else {
            let keys = Object.keys(this.state.permissionGroup);
            let permissionArr = [];
            keys.forEach(key => {
                permissionArr = [...permissionArr, ...this.state.permissionGroup[key]];
            });

            var data = {
                role: this.state.currentRole,
                permissions: permissionArr
            }

            if (this.state.currentUser) data.user = this.state.currentUser;

            this.props.updateRolePermissions(data);
        }
    }

    render() {
        var { permissions, config } = this.props;
        var { isLoading, currentRole, permissionGroup, currentUser } = this.state;

        var roles = [];
        if (config.roles) {
            roles = config.roles.slice(1);
        }
        var users = [];
        if (currentRole) {
            users = config.users.filter(item => item.role == currentRole);
        }

        //console.log(permissions);
        const SetttingPermissions = ['config', 'telegram', 'product_status', 'services', 'list_diseases', 'blood_type', 'customer_class', 'customer_status', 'customer_interactive_status', 'customer_interactive_type', 'customer_source', 'unit', 'relationship_types', 'media_categories', 'document_categories'];
        const CustomerPermissions = ['customer', 'customer_medical', 'lookup_customer', 'customer_telesale', 'customer_potential', 'customer_consultant_direct', 'customer_services', 'voucher', 'customer_schedule', 'customer_group'];
        const BranchManagementPermissions = ['branch', 'user', 'role_group', 'roles', 'documents'];
        const WarehousePermissions = ['warehouse_supplier', 'product_receipt_export', 'order', 'product_warehouse', 'product_supplier', 'product_group', 'product', 'product_receipt_import'];
        const ProjectPermissions = ['project', 'project_based_job'];
        const FanpagePermissions = ['messenger', 'page_manager'];
        const ThuchiPermissions = ['cash_receipt', 'payment_voucher', 'cash_book'];
        const JobsPermissions = ['functional_area', 'functional', 'jobs', 'shift_scheduling'];
        const DoctorAssistantPermissions = ['dental_patient_cases', 'doctor_assistant_status', 'customer_doctor_assistant'];
        const QuanLyThePermissions = ['card_issued_count', 'card_release_tracking', 'card_type', 'card_pending_customers'];
        const HoatdongPermissions = ['history', 'view_phonenumber'];

        let SetttingGroup = [];
        let CustomerGroup = [];
        let others = [];
        let BranchManagementGroup = [];
        let WarehouseGroup = [];
        let ProjectGroup = [];
        let FanpageGroup = [];
        let ThuchiGroup = [];
        let JobsGroup = [];
        let DoctorAssistantGroup = [];
        let QuanLyTheGroup = [];
        let HoatdongGroup = [];

        permissions.forEach(permission => {
            if (SetttingPermissions.indexOf(permission.resource) >= 0) {
                SetttingGroup.push(permission);
            } else if (CustomerPermissions.indexOf(permission.resource) >= 0) {
                CustomerGroup.push(permission);
            } else if (BranchManagementPermissions.indexOf(permission.resource) >= 0) {
                BranchManagementGroup.push(permission);
            } else if (WarehousePermissions.indexOf(permission.resource) >= 0) {
                WarehouseGroup.push(permission);
            } else if (ProjectPermissions.indexOf(permission.resource) >= 0) {
                ProjectGroup.push(permission);
            } else if (FanpagePermissions.indexOf(permission.resource) >= 0) {
                FanpageGroup.push(permission);
            } else if (ThuchiPermissions.indexOf(permission.resource) >= 0) {
                ThuchiGroup.push(permission);
            } else if (JobsPermissions.indexOf(permission.resource) >= 0) {
                JobsGroup.push(permission);
            } else if (DoctorAssistantPermissions.indexOf(permission.resource) >= 0) {
                DoctorAssistantGroup.push(permission);
            } else if (QuanLyThePermissions.indexOf(permission.resource) >= 0) {
                QuanLyTheGroup.push(permission);
            } else if (HoatdongPermissions.indexOf(permission.resource) >= 0) {
                HoatdongGroup.push(permission);
            } else {
                others.push(permission);
            }
        });

        SetttingGroup = chunkGroup(SetttingGroup);
        CustomerGroup = chunkGroup(CustomerGroup);
        others = chunkGroup(others);
        BranchManagementGroup = chunkGroup(BranchManagementGroup)
        WarehouseGroup = chunkGroup(WarehouseGroup)
        ProjectGroup = chunkGroup(ProjectGroup)
        FanpageGroup = chunkGroup(FanpageGroup)
        ThuchiGroup = chunkGroup(ThuchiGroup)
        JobsGroup = chunkGroup(JobsGroup)
        DoctorAssistantGroup = chunkGroup(DoctorAssistantGroup)
        QuanLyTheGroup = chunkGroup(QuanLyTheGroup)
        HoatdongGroup = chunkGroup(HoatdongGroup)

        return (
            <div className="mb-4">
                <PageTitle title="Phân quyền user" routes={routes} />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={false}
                        showSearch={false}
                        showFilter={false}
                    >
                        <span>Chọn loại quyền:&nbsp;</span>
                        <Select
                            showSearch
                            placeholder="Chọn một vai trò"
                            style={{ width: '250px' }}
                            onChange={(value) => this.onChangeRole(value)}
                            options={config.role_trees}
                            optionFilterProp="children"
                            allowClear
                            className="table-button"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                        />
                        <BaseSelect
                            options={users || []}
                            showSearch
                            allowClear={false}
                            value={currentUser || null}
                            optionValue="user_id"
                            optionLabel="full_name"
                            style={{ width: '250px' }}
                            placeholder="Tất cả nhân viên"
                            className="table-button"
                            onChange={(value) => this.onChangeUser(value)}
                        />

                    </TableActionBar>
                </div>

                <div className="mt-4"></div>
                <Alert
                    message="Lưu ý"
                    description="User với quyền Admin luôn có toàn bộ quyền truy cập."
                    className='fix-alert-element'
                    showIcon
                />
                <div className="mt-4"></div>
                {
                    isLoading ? (
                        <div className="text-center">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <React.Fragment>
                            <Timeline>
                                <Timeline.Item>
                                    <h6>Cài đặt</h6>
                                    <PermissionGroup groups={SetttingGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <h6>Quản lý cơ sở</h6>
                                    <PermissionGroup groups={BranchManagementGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <h6>Khách hàng & dịch vụ</h6>
                                    <PermissionGroup groups={CustomerGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <h6>Bác sĩ & Phụ tá</h6>
                                    <PermissionGroup groups={DoctorAssistantGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <h6>Kho</h6>
                                    <PermissionGroup groups={WarehouseGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <h6>Quản lý dự án</h6>
                                    <PermissionGroup groups={ProjectGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <h6>Quản lý fanpage</h6>
                                    <PermissionGroup groups={FanpageGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <h6>Thu chi</h6>
                                    <PermissionGroup groups={ThuchiGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <h6>Phân ca</h6>
                                    <PermissionGroup groups={JobsGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <h6>Thẻ khách hàng</h6>
                                    <PermissionGroup groups={QuanLyTheGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <h6>Hoạt động</h6>
                                    <PermissionGroup groups={HoatdongGroup} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />
                                </Timeline.Item>
                                <Timeline.Item>
                                    <h6>Các quyền khác</h6>
                                    <PermissionGroup groups={others} permissionGroup={permissionGroup} onCheck={this.onCheck} onCheckAll={this.onCheckAll} />
                                </Timeline.Item>
                            </Timeline>
                            <Row justify="end">
                                <Button type="primary" onClick={() => this.submit()}>Xác nhận</Button>
                            </Row>
                        </React.Fragment>
                    )
                }


            </div>
        )
    }
}

function mapStateToProps(state) {
    //console.log(state)
    return {
        permissions: state.permission.permissions,
        permissionsByRole: state.permission.permissionsByRole,
        config: state.config,
        // users: state.user.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllPermissions: () => dispatch(getAllPermissions()),
        updateRolePermissions: (data) => dispatch(updateRolePermissions(data)),
        getPermissionsByRole: (role) => dispatch(getPermissionsByRole(role)),
        getPermissionsByUser: (user) => dispatch(getPermissionsByUser(user)),
        getAllUsers: (filter) => dispatch(getAllUsers(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPermission);