import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Descriptions, Divider, Tag, Button, Row, Col, List, Modal, Image, Space, Card, Collapse, Typography } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined, MedicineBoxOutlined, UserOutlined, FolderOutlined, FileImageOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import { isValidDate } from '../../utils/helpers';
import CustomerServiceAddForm from '../customer-services/CustomerServiceAddForm';
import CustomerServiceEditForm from '../customer-services/CustomerServiceEditForm';
import HtmlParser from 'react-html-parser';
import NumberFormat from 'react-number-format';
import { getSpecificService, removeService } from '../../redux/actions/CustomerServicesActions';
import ExpandableText from '../../components/ExpandableText';

const { confirm } = Modal;
const { Panel } = Collapse;
const { Text } = Typography;

class CustomerServicesList extends Component {
    constructor() {
        super();
        this.state = {
            isOpenEditForm: false,
            isOpenAddForm: false,
            currentData: null
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenEditForm(isOpenEditForm, currentData = null) {
        this.setState({
            isOpenEditForm: isOpenEditForm,
            currentData: currentData,
            currentCustomer: this.props.currentItem
        });
    }

    toggleOpenForm(isOpenAddForm) {
        this.setState({
            isOpenAddForm: isOpenAddForm,
            currentCustomer: this.props.currentItem
        });
    }

    onEdit(id) {
        this.props.getSpecificService(id).then(res => {
            this.toggleOpenEditForm(true, res);
        })
    }

    openRemove(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeService(id).then(() => {
                setTimeout(() => {
                    this.props.reloadData();
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    render() {
        var { currentData, isOpenAddForm, isOpenEditForm, currentCustomer } = this.state;
        var { currentItem } = this.props;

        return (
            <div>
                {
                    checkPermission('customer_services', 'list') ? (
                        <>
                            {/* <Divider orientation="left" orientationMargin="0" className='mt-4 mb-0'>
                                <Tag color='#0b2b33'>6</Tag> Dịch vụ đã chốt
                            </Divider> */}
                            <Row justify="end" className='mb-1'>
                                <Button
                                    disabled={!checkPermission('customer_services', 'create')}
                                    type="dashed"
                                    className='color-blue'
                                    size='small'
                                    onClick={() => this.toggleOpenForm(true)}
                                    icon={<PlusOutlined />}>
                                    Thêm mới
                                </Button>
                            </Row>

                            {currentItem.services_selected && currentItem.services_selected.length > 0 ? (
                                <Row gutter={16}>
                                    {
                                        currentItem.services_selected.map((item, index) => {
                                            return (
                                                <Col key={index} xs={24} md={12} lg={8} className='box-item-service customer-info-data mb-3'>
                                                    <Card size="small"
                                                        title={<>{<MedicineBoxOutlined />} {item.service_name}</>}
                                                        style={{ background: '#e7ecd8' }}
                                                        extra={
                                                            <>
                                                                {checkPermission('customer_services', 'update') ? (
                                                                    <a onClick={() => this.onEdit(item.id)} type="link" key="list-vertical-edit">
                                                                        <EditOutlined /> Sửa
                                                                    </a>
                                                                ) : null}

                                                                {checkPermission('customer_services', 'remove') ? (
                                                                    <>
                                                                        <Divider type='vertical' />
                                                                        <span className='delete-icon' onClick={() => this.openRemove([item.id])} type="link" key="list-vertical-delete">
                                                                            <DeleteOutlined /> Xóa
                                                                        </span>
                                                                    </>
                                                                ) : null}
                                                            </>
                                                        }
                                                    >
                                                        <Row gutter={16}>
                                                            <Col xs={24} md={24}>
                                                                <Descriptions size="small">
                                                                    <Descriptions.Item label="Giá bán">
                                                                        <NumberFormat value={item.sale_price} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                    </Descriptions.Item>
                                                                </Descriptions>
                                                                <Divider className='no-margin' />
                                                                <Descriptions size="small">
                                                                    <Descriptions.Item label="Số lượng">
                                                                        <NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} />
                                                                    </Descriptions.Item>
                                                                </Descriptions>
                                                                <Divider className='no-margin' />
                                                                <Descriptions size="small">
                                                                    <Descriptions.Item label="Thành tiền">
                                                                        <NumberFormat value={item?.amount} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                    </Descriptions.Item>
                                                                </Descriptions>
                                                                <Divider className='no-margin' />
                                                                <Descriptions size="small">
                                                                    <Descriptions.Item label="Ngày chốt">
                                                                        {isValidDate(item?.selected_at, false)}
                                                                    </Descriptions.Item>
                                                                </Descriptions>
                                                                <Divider className='no-margin' />
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={16} className='customer-service-box'>
                                                            <Col xs={24} md={24}>
                                                                <Collapse
                                                                    expandIconPosition="end"
                                                                    ghost
                                                                    defaultActiveKey={[]}
                                                                >
                                                                    <Panel
                                                                        header={<><span className="font-weight-500"><FileImageOutlined /> Ảnh</span></>}
                                                                        key="1"
                                                                    >
                                                                        <Row gutter={16}>
                                                                            <Col xs={24} md={24} className='mb-2'>
                                                                                <div className='font-weight-500'>Ảnh before</div>
                                                                                {
                                                                                    Array.isArray(item.condition_before) && item.condition_before.length ? (
                                                                                        <Image.PreviewGroup>
                                                                                            <Space wrap>
                                                                                                {
                                                                                                    item.condition_before.map((item, index) => {
                                                                                                        return (
                                                                                                            <Image
                                                                                                                key={index}
                                                                                                                height={40}
                                                                                                                width={40}
                                                                                                                style={{ objectFit: 'cover', objectPosition: 'center' }}
                                                                                                                src={item?.src}
                                                                                                            />
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </Space>
                                                                                        </Image.PreviewGroup>
                                                                                    ) : (
                                                                                        <div style={{ textAlign: 'center', width: '100%' }} className='no-data'>Chưa có dữ liệu</div>
                                                                                    )
                                                                                }
                                                                            </Col>
                                                                            <Col xs={24} md={24}>
                                                                                <div className='font-weight-500'>Ảnh after</div>
                                                                                {
                                                                                    Array.isArray(item.condition_after) && item.condition_after.length ? (
                                                                                        <Image.PreviewGroup>
                                                                                            <Space wrap>
                                                                                                {
                                                                                                    item.condition_after.map((item, index) => {
                                                                                                        return (
                                                                                                            <Image
                                                                                                                key={index}
                                                                                                                height={40}
                                                                                                                width={40}
                                                                                                                style={{ objectFit: 'cover', objectPosition: 'center' }}
                                                                                                                src={item?.src}
                                                                                                            />
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </Space>
                                                                                        </Image.PreviewGroup>
                                                                                    ) : (
                                                                                        <div style={{ textAlign: 'center', width: '100%' }} className='no-data'>Chưa có dữ liệu</div>
                                                                                    )
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </Panel>
                                                                </Collapse>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={16} className='customer-service-box'>
                                                            <Col xs={24} md={24}>
                                                                <Collapse
                                                                    expandIconPosition="end"
                                                                    ghost
                                                                    defaultActiveKey={[]}
                                                                >
                                                                    <Panel
                                                                        header={<><span className="font-weight-500"><FolderOutlined /> Lần khám</span></>}
                                                                        key="1"
                                                                    >
                                                                        {
                                                                            item?.examination_visit && item?.examination_visit.length ? (
                                                                                <>
                                                                                    <Row gutter={16}>
                                                                                        {item.examination_visit.map((item2, index2) => {
                                                                                            return (
                                                                                                <div key={index2} style={{ borderBottom: '1px solid #dedede' }} className='mb-2 pb-2'>
                                                                                                    <Col xs={24} md={24}>
                                                                                                        <Descriptions size="small">
                                                                                                            <Descriptions.Item label="Thời điểm">
                                                                                                                {isValidDate(item2.time)}
                                                                                                            </Descriptions.Item>
                                                                                                        </Descriptions>
                                                                                                    </Col>
                                                                                                    <Col xs={24} md={24}>
                                                                                                        <Descriptions size="small">
                                                                                                            <Descriptions.Item label="Bác sĩ">
                                                                                                                {item2.doctor_charge_id}
                                                                                                            </Descriptions.Item>
                                                                                                        </Descriptions>
                                                                                                    </Col>
                                                                                                    <Col xs={24} md={24}>
                                                                                                        <Descriptions size="small">
                                                                                                            <Descriptions.Item label="Phụ tá">
                                                                                                                {item2.assistant_doctor_id}
                                                                                                            </Descriptions.Item>
                                                                                                        </Descriptions>
                                                                                                    </Col>
                                                                                                    <Col xs={24} md={24}>
                                                                                                        <div className="font-weight-500">Kết quả khám:</div>
                                                                                                        {HtmlParser(item.note)}
                                                                                                    </Col>
                                                                                                </div>
                                                                                            )
                                                                                        })}
                                                                                    </Row>
                                                                                </>
                                                                            ) : (
                                                                                <Row gutter={16}><div style={{ textAlign: 'center', width: '100%' }} className='no-data'>Chưa có dữ liệu</div></Row>
                                                                            )
                                                                        }
                                                                    </Panel>
                                                                </Collapse>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={16} className='customer-service-box'>
                                                            <Col xs={24} md={24}>
                                                                <Collapse
                                                                    expandIconPosition="end"
                                                                    ghost
                                                                    defaultActiveKey={[]}
                                                                >
                                                                    <Panel
                                                                        header={<><span className="font-weight-500"><UserOutlined /> Nhân viên tư vấn</span></>}
                                                                        key="1"
                                                                    >
                                                                        {
                                                                            item?.consultant_directly && item?.consultant_directly.length ? (
                                                                                <>
                                                                                    <Row gutter={16}>
                                                                                        {
                                                                                            item?.consultant_directly.map((item2, index) => {
                                                                                                return (
                                                                                                    <div key={index} style={{ borderBottom: '1px solid #dedede' }} className='mb-2 pb-2'>
                                                                                                        <Col xs={24} md={24}>
                                                                                                            <Descriptions size="small">
                                                                                                                <Descriptions.Item label="Nhân viên">
                                                                                                                    {item2.consultant_user}
                                                                                                                </Descriptions.Item>
                                                                                                            </Descriptions>
                                                                                                        </Col>
                                                                                                        <Col xs={24} md={24}>
                                                                                                            <Descriptions size="small">
                                                                                                                <Descriptions.Item label="Thời điểm">
                                                                                                                    {isValidDate(item2.consultant_date)}
                                                                                                                </Descriptions.Item>
                                                                                                            </Descriptions>
                                                                                                        </Col>
                                                                                                        <Col xs={24} md={24}>
                                                                                                            <div className="font-weight-500">Kết quả tư vấn:</div>
                                                                                                            {HtmlParser(item2.note)}
                                                                                                        </Col>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Row>
                                                                                </>) : (
                                                                                <Row gutter={16}><div style={{ textAlign: 'center', width: '100%' }} className='no-data'>Chưa có dữ liệu</div></Row>
                                                                            )
                                                                        }
                                                                    </Panel>
                                                                </Collapse>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={16} className='mt-2'>
                                                            <Col xs={24} md={24}>
                                                                <Text strong>Ghi chú: </Text>
                                                                <ExpandableText
                                                                    text={item.note}
                                                                    rows={2}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            )
                                        })}
                                </Row>
                            ) : null}

                            {/* <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <List
                                        className='list-in-detail-customer dv-chot'
                                        itemLayout="vertical"
                                        rowKey='id'
                                        size="small"
                                        locale={{ emptyText: "Chưa có dữ liệu" }}
                                        dataSource={currentItem.services_selected || []}
                                        renderItem={(item, index) => {
                                            const IconAction = [];
                                            if (checkPermission('customer_services', 'update')) {
                                                IconAction.push(<a onClick={() => this.onEdit(item.id)} type='link' key="list-vertical-edit"><EditOutlined /> Sửa</a>)
                                            }
                                            if (checkPermission('customer_services', 'remove')) {
                                                IconAction.push(<span className='delete-icon' onClick={() => this.openRemove([item.id])} type='link' key="list-vertical-delete"><DeleteOutlined /> Xóa</span>)
                                            }

                                            return (
                                                <List.Item
                                                    key={index}
                                                    actions={IconAction}
                                                    className='mb-3'
                                                    locale={{ emptyText: "Chưa có dữ liệu" }}
                                                >
                                                    <List.Item.Meta
                                                        title={<><MedicineBoxOutlined /> {item.service_name}</>}
                                                        className='mb-0'
                                                    />
                                                    <Row gutter={16}>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Giá gốc">
                                                                    <NumberFormat value={item.regular_price} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Giá bán">
                                                                    <NumberFormat value={item.sale_price} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Số lượng">
                                                                    <NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} />
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Thành tiền">
                                                                    <NumberFormat value={item?.amount} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Ngày chốt">
                                                                    {isValidDate(item?.selected_at, false)}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Người tạo">
                                                                    {item?.created_user}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Ghi chú">
                                                                    {HtmlParser(item.note)}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                        </Col>
                                                        <Col xs={24} md={12} className='mb-2'>
                                                            <Card size='small' className='border-dashed'>
                                                                <h6 className='h6-title'>Ảnh before</h6>
                                                                {
                                                                    Array.isArray(item.condition_before) && item.condition_before.length ? (
                                                                        <Image.PreviewGroup>
                                                                            <Space wrap>
                                                                                {
                                                                                    item.condition_before.map(item => {
                                                                                        return (
                                                                                            <Image
                                                                                                height={40}
                                                                                                width={40}
                                                                                                src={item?.src}
                                                                                                style={{
                                                                                                    border: '1px solid #dedede',
                                                                                                    objectFit: 'cover',
                                                                                                    objectPosition: 'center',
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Space>
                                                                        </Image.PreviewGroup>
                                                                    ) : null
                                                                }
                                                            </Card>
                                                        </Col>
                                                        <Col xs={24} md={12}>
                                                            <Card size='small' className='border-dashed'>
                                                                <h6 className='h6-title'>Ảnh after</h6>
                                                                {
                                                                    Array.isArray(item.condition_after) && item.condition_after.length ? (
                                                                        <Image.PreviewGroup>
                                                                            <Space wrap>
                                                                                {
                                                                                    item.condition_after.map((item, index) => {
                                                                                        return (
                                                                                            <Image
                                                                                                key={index}
                                                                                                height={40}
                                                                                                width={40}
                                                                                                src={item?.src}
                                                                                                style={{
                                                                                                    border: '1px solid #dedede',
                                                                                                    objectFit: 'cover',
                                                                                                    objectPosition: 'center',
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Space>
                                                                        </Image.PreviewGroup>
                                                                    ) : null
                                                                }
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        item?.examination_visit && item?.examination_visit.length ? (
                                                            <>
                                                                <h6 className='h6-title'>Lần khám:</h6>
                                                                <Row gutter={16}>
                                                                    {item.examination_visit.map((item2, index) => {
                                                                        return (
                                                                            <Col key={index} xs={24} md={12} className='mb-2'>
                                                                                <Card size="small" className='card-service-detail'>
                                                                                    <Col xs={24} md={24}>
                                                                                        <Descriptions size="small">
                                                                                            <Descriptions.Item label="Thời điểm">
                                                                                                {isValidDate(item2.time)}
                                                                                            </Descriptions.Item>
                                                                                        </Descriptions>
                                                                                    </Col>
                                                                                    <Col xs={24} md={24}>
                                                                                        <Descriptions size="small">
                                                                                            <Descriptions.Item label="Bác sĩ">
                                                                                                {item2.doctor_charge_id}
                                                                                            </Descriptions.Item>
                                                                                        </Descriptions>
                                                                                    </Col>
                                                                                    <Col xs={24} md={24}>
                                                                                        <Descriptions size="small">
                                                                                            <Descriptions.Item label="Phụ tá">
                                                                                                {item2.assistant_doctor_id}
                                                                                            </Descriptions.Item>
                                                                                        </Descriptions>
                                                                                    </Col>
                                                                                    <Col xs={24} md={24}>
                                                                                        <h6 className="h6-title">Kết quả khám:</h6>
                                                                                        {HtmlParser(item2.note)}
                                                                                    </Col>
                                                                                </Card>
                                                                            </Col>
                                                                        )
                                                                    })}
                                                                </Row>
                                                            </>
                                                        ) : null
                                                    }
                                                    {
                                                        item?.consultant_directly && item?.consultant_directly.length ? (
                                                            <>
                                                                <h6 className='h6-title mt-2'>Nhân viên tư vấn trực tiếp:</h6>
                                                                <Row gutter={16}>
                                                                    {
                                                                        item?.consultant_directly.map((item2, index) => {
                                                                            return (
                                                                                <Col key={index} xs={24} md={12} className='mb-2'>
                                                                                    <Card size="small" className='card-service-detail'>
                                                                                        <Col xs={24} md={24}>
                                                                                            <Descriptions size="small">
                                                                                                <Descriptions.Item label="Nhân viên">
                                                                                                    {item2.consultant_user}
                                                                                                </Descriptions.Item>
                                                                                            </Descriptions>
                                                                                        </Col>
                                                                                        <Col xs={24} md={24}>
                                                                                            <Descriptions size="small">
                                                                                                <Descriptions.Item label="Thời điểm">
                                                                                                    {isValidDate(item2.consultant_date)}
                                                                                                </Descriptions.Item>
                                                                                            </Descriptions>
                                                                                        </Col>
                                                                                        <Col xs={24} md={24}>
                                                                                            <h6 className="h6-title">Kết quả tư vấn:</h6>
                                                                                            {HtmlParser(item2.note)}
                                                                                        </Col>
                                                                                    </Card>
                                                                                </Col>
                                                                            )
                                                                        })
                                                                    }
                                                                </Row>
                                                            </>) : null
                                                    }
                                                </List.Item>
                                            )
                                        }}
                                    />
                                </Col>
                            </Row> */}
                        </>
                    ) : null
                }
                <CustomerServiceAddForm
                    visible={isOpenAddForm}
                    currentData={currentData}
                    currentCustomer={currentCustomer}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <CustomerServiceEditForm
                    visible={isOpenEditForm}
                    currentData={currentData}
                    currentCustomer={currentCustomer}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenEditForm(false)}
                />
            </div >
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser,
        branchActiveList: state.BranchReducer.branchActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        removeService: (ids) => dispatch(removeService(ids)),
        getSpecificService: (id) => dispatch(getSpecificService(id))
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(CustomerServicesList);

// Sử dụng forwardRef để truyền ref xuống component
// Mục đích để cho parent component có thể gọi method của child component
const ConnectedCustomerServicesSelected = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(CustomerServicesList);

export default React.forwardRef((props, ref) => (
    <ConnectedCustomerServicesSelected {...props} ref={ref} />
));
