import React, { useEffect, useState } from "react";
import { Button, Input, Row, Col, Collapse, Modal } from "antd";
import ImageSelector from '../../components/ImageSelector';
import { _newLine, generateId } from "../../utils/helpers";
import moment from "moment";

const { Panel } = Collapse;
const { confirm } = Modal;
const datetime = moment().format('YYYY-MM-DD HH:mm:ss');

const BeforeAfterForm = (props) => {

    const { onChangeData, currentData } = props;

    const [formData, setFormData] = useState([]);

    useEffect(() => {
        if (!currentData || currentData.length === 0) {
            setFormData(defaultFormData.data);
        } else {
            setFormData(currentData);
        }
    }, [currentData]);

    // Hàm xử lý thêm record mới
    const addRecord = (recordKey) => {
        const newRecord = {
            manual_id: generateId(),
            created_at: datetime,
            angles: angles,
            note: "",
        };

        setFormData((prevData) => ({
            ...prevData,
            [recordKey]: {
                ...prevData[recordKey],
                records: [...prevData[recordKey].records, newRecord],
            },
        }));
    };

    // Hàm xử lý thay đổi ghi chú
    const handleNoteChange = (recordKey, recordId, newNote) => {
        setFormData((prevData) => ({
            ...prevData,
            [recordKey]: {
                ...prevData[recordKey],
                records: prevData[recordKey].records.map((record) =>
                    record.manual_id === recordId ? { ...record, note: newNote } : record
                ),
            },
        }));
    };

    // Hàm xử lý upload ảnh
    const handleImageChange = (recordKey, recordId, angleId, fileList, changeDetails) => {
        const { action, removedId } = changeDetails || {};

        const uploadedImages = fileList.map((file) => ({
            manual_id: file.manual_id,
            src: file.originalImage,
        }));

        setFormData((prevData) => ({
            ...prevData,
            [recordKey]: {
                ...prevData[recordKey],
                records: prevData[recordKey].records.map((record) => {
                    if (record.manual_id !== recordId) return record; // Bỏ qua nếu không khớp recordId

                    return {
                        ...record,
                        angles: record.angles.map((angle) => {
                            if (angle.manual_id !== angleId) return angle; // Bỏ qua nếu không khớp angleId

                            let updatedImages = angle.images || []; // Xử lý nếu angle.images chưa được khởi tạo

                            if (action === 'remove') {
                                // Xóa ảnh dựa trên `removedId`
                                updatedImages = updatedImages.filter((img) => img.manual_id !== removedId);
                            } else {
                                // Thêm ảnh mới, tránh trùng lặp
                                updatedImages = [
                                    ...updatedImages.filter(
                                        (img) => !uploadedImages.some((newImg) => newImg.manual_id === img.manual_id)
                                    ),
                                    ...uploadedImages,
                                ];
                            }

                            return {
                                ...angle,
                                images: updatedImages,
                            };
                        }),
                    };
                }),
            },
        }));
    };

    const handleDelete = (recordKey, recordId) => {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá record này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => {
                setFormData((prevData) => ({
                    ...prevData,
                    [recordKey]: {
                        ...prevData[recordKey],
                        records: prevData[recordKey].records.filter((record) => record.manual_id !== recordId),
                    },
                }));
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            },
            onCancel() { },
        })
    }

    useEffect(() => {
        if (formData) {
            onChangeData(formData);
        }
    }, [formData]);

    return (
        <Row gutter={16} className="fix-padding-collapse">
            {Object.keys(formData).map((key) => {
                const data = formData[key];
                return (
                    <Col xs={24} md={24} key={key}>
                        <Collapse defaultActiveKey={['0']} ghost>
                            <Panel header={<strong>{data.name}</strong>} forceRender key={key}>
                                <Row gutter={16}>
                                    {data?.records?.map((record, index) => (
                                        <Col xs={24} md={12} key={index}>
                                            <div className="border-dashed line-profile-image">
                                                <Row gutter={16}>
                                                    {record?.angles?.map((angle, angleIndex) => (
                                                        <Col xs={24} md={12} key={angleIndex}>
                                                            <div className="mb-2">
                                                                <label>{angleIndex + 1}. {angle.name}</label>
                                                                <ImageSelector
                                                                    onChangeData={(fileList, changeDetails) => handleImageChange(key, record.manual_id, angle.manual_id, fileList, changeDetails)} // Gắn hàm xử lý thay đổi dữ liệu
                                                                    multiple={false} // Cho phép chọn nhiều ảnh
                                                                    maxCount={1} // Giới hạn tối đa 50 ảnh
                                                                    uploadText="Chọn ảnh"
                                                                    defaultFileList={angle.images}
                                                                />
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>

                                                <div>
                                                    <label>Ghi chú</label>
                                                    <Input.TextArea
                                                        rows={2}
                                                        value={_newLine(record.note)}
                                                        onChange={(e) => handleNoteChange(key, record.manual_id, e.target.value)}
                                                    />
                                                </div>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => handleDelete(key, record.manual_id)}
                                                    danger
                                                    size="small"
                                                    className="profile-image-delete-button"
                                                >
                                                    Xóa
                                                </Button>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                                <Button
                                    onClick={() => addRecord(key)}
                                    type="dashed"
                                    size="small"
                                    className="mt-10 mb-10"
                                    style={{ color: '#1890ff' }}
                                >
                                    Thêm record
                                </Button>
                            </Panel>
                        </Collapse>
                    </Col>
                );
            })}
        </Row>
    );
};

export default BeforeAfterForm;

const angles = [
    {
        manual_id: generateId(),
        name: 'Góc chính diện',
        images: [], // [{id: 1, src: "https://picsum.photos/200"}, {id: 2, src: "https://picsum.photos/id/237/200/300"}],
    },
    {
        manual_id: generateId(),
        name: 'Góc chính diện cười',
        images: [],
    },
    {
        manual_id: generateId(),
        name: 'Góc quay trái',
        images: [],
    },
    {
        manual_id: generateId(),
        name: 'Góc quay trái cười',
        images: [],
    },
    {
        manual_id: generateId(),
        name: 'Hàm chính diện',
        images: [],
    },
    {
        manual_id: generateId(),
        name: 'Hàm chếch 45° trái',
        images: [],
    },
    {
        manual_id: generateId(),
        name: 'Hàm chếch 45° phải',
        images: [],
    },
    {
        manual_id: generateId(),
        name: 'Hàm trên chính diện',
        images: [],
    },
    {
        manual_id: generateId(),
        name: 'Hàm trên chính diện có uốn lưỡi',
        images: [],
    },
    {
        manual_id: generateId(),
        name: 'Góc 45° trái',
        images: [],
    },
    {
        manual_id: generateId(),
        name: 'Góc 45° trái cười',
        images: [],
    },
    {
        manual_id: generateId(),
        name: 'Góc 45° phải',
        images: [],
    },
    {
        manual_id: generateId(),
        name: 'Góc 45° phải cười',
        images: [],
    },
    {
        manual_id: generateId(),
        name: 'Phim chính diện',
        images: [],
    },
    {
        manual_id: generateId(),
        name: 'Phim quay trái',
        images: [],
    }
];

const defaultFormData = {
    service_id: 78,
    data: [
        {
            type: "before",
            name: "Ảnh before",
            records: [
                {
                    manual_id: generateId(),
                    created_at: datetime,
                    angles: angles,
                    note: "",
                },
            ],
        },
        {
            type: "after",
            name: "Ảnh after",
            records: [
                {
                    manual_id: generateId(),
                    created_at: datetime,
                    angles: angles,
                    note: "",
                },
            ],
        }
    ]
};