import React from 'react';

import {
    SettingOutlined,
    UserOutlined,
    DashboardOutlined,
    UsergroupDeleteOutlined,
    FacebookOutlined,
    ClusterOutlined,
    ShoppingOutlined,
    FileDoneOutlined,
    QuestionCircleOutlined,
    TeamOutlined,
    FormOutlined,
    MessageOutlined,
    HistoryOutlined,
    ClockCircleOutlined,
    ShopOutlined,
    DollarOutlined,
    CustomerServiceOutlined,
    GiftOutlined,
    ProjectOutlined,
    OneToOneOutlined,
    MedicineBoxOutlined,
    FileTextOutlined,
    DatabaseOutlined,
    FieldTimeOutlined,
    IdcardOutlined,
    UnlockOutlined,
    FileImageOutlined
} from '@ant-design/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const menu = [
    {
        path: '/',
        name: 'Dashboard',
        icon: <DashboardOutlined className="sidebar-icon" />,
        permission: null
    },
    {
        path: '#',
        name: 'Cài đặt',
        icon: <SettingOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/config',
                name: 'Cài đặt hệ thống',
                permission: 'config'
            },
            {
                path: '/service',
                name: 'Danh mục dịch vụ',
                permission: 'services',
            },
            {
                path: '/list-diseases',
                name: 'Danh sách bệnh',
                permission: 'list_diseases',
            },
            {
                path: '/blood-type',
                name: 'Nhóm máu',
                permission: 'blood_type',
            },
            {
                path: '/customer-class',
                name: 'Hạng khách hàng',
                permission: 'customer_class'
            },
            {
                path: '/customer-status',
                name: 'Trạng thái khách hàng',
                permission: 'customer_status'
            },
            {
                path: '/customer-interactive-status',
                name: 'Trạng thái tương tác',
                permission: 'customer_interactive_status'
            },
            {
                path: '/customer-interactive-type',
                name: 'Loại tương tác',
                permission: 'customer_interactive_type'
            },
            {
                path: '/customer-source',
                name: 'Nguồn khách hàng',
                permission: 'customer_source'
            },
            {
                path: '/unit',
                name: 'Đơn vị tính',
                permission: 'unit'
            },
            {
                path: '/product-status',
                name: 'Tình trạng sản phẩm',
                permission: 'product_status'
            },
            {
                path: '/relationship-types',
                name: 'Quan hệ gia đình',
                permission: 'relationship_types'
            },
            // {
            //     path: '/media-categories', // Không dùng
            //     name: 'Danh mục media',
            //     permission: 'media_categories'
            // },
            {
                path: '/document-categories',
                name: 'Danh mục văn bản',
                permission: 'document_categories'
            },
            {
                path: '/telegram',
                name: 'Cài đặt telegram',
                permission: 'telegram'
            }
        ],
        isAdminOnly: true
    },
    {
        path: '#',
        name: 'Quản lý cơ sở',
        icon: <ClusterOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/branches',
                name: 'Cơ sở',
                permission: 'branch'
            },
            {
                path: '/documents',
                name: 'Văn bản',
                permission: 'documents'
            },
            {
                path: '/users',
                name: 'Nhân viên',
                permission: 'user'
            },
            {
                path: '/roles',
                name: 'Vai trò',
                permission: 'roles'
            },
            {
                path: '/role-group',
                name: 'Nhóm vai trò',
                permission: 'role_group'
            },
            {
                path: '/permissions',
                name: 'Phân quyền',
                permission: 'permissions'
            }
        ],
        isAdminOnly: true
    },
    {
        path: '#',
        name: 'Quản lý fanpages',
        icon: <FacebookOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/page-list',
                name: 'Danh sách fanpages',
                permission: 'page_manager'
            },
            {
                path: '/messenger',
                name: 'Messenger',
                permission: 'messenger'
            }
        ]
    },
    {
        path: '#',
        name: 'Khách hàng',
        icon: <UsergroupDeleteOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/customer-data?tabs=lookup',
                name: 'Dữ liệu khách hàng',
                permission: 'customer_data'
            },
            {
                path: '/customer',
                name: 'Danh sách khách hàng',
                permission: 'customer'
            },
            {
                path: '/lookup-customer?tab=all',
                name: 'Tra cứu khách hàng',
                permission: 'lookup_customer'
            },
            {
                path: '/customer-potential',
                name: 'Khách hàng tiềm năng',
                permission: 'customer_potential'
            },
            {
                path: '/customer-group',
                name: 'Danh sách nhóm',
                permission: 'customer_group'
            }
        ]
    },
    {
        path: '/customer-schedule?tab=1',
        name: 'Lịch hẹn',
        icon: <ClockCircleOutlined className="sidebar-icon" />,
        permission: 'customer_schedule'
    },
    {
        path: '/customer-telesale',
        name: 'Telesale',
        icon: <CustomerServiceOutlined className="sidebar-icon" />,
        permission: 'customer_telesale'
    },
    {
        path: '/customer-consultant-direct',
        name: 'Tư vấn trực tiếp',
        icon: <OneToOneOutlined className="sidebar-icon" />,
        permission: 'customer_consultant_direct'
    },
    {
        path: '/customer-services',
        name: 'Dịch vụ',
        icon: <DatabaseOutlined className="sidebar-icon" />,
        permission: 'customer_services'
    },
    // {
    //     path: '#',
    //     name: 'Bác sĩ, Phụ tá',
    //     icon: <MedicineBoxOutlined className="sidebar-icon" />,
    //     permission: null,
    //     children: [
    //         {
    //             path: '/customer-doctor-assistant',
    //             name: 'Khách hàng',
    //             permission: 'customer_doctor_assistant'
    //         },
    //         // {
    //         //     path: '/doctor-assistant-status',
    //         //     name: 'Danh sách bác sĩ, phụ tá',
    //         //     permission: 'doctor_assistant_status'
    //         // },
    //         // {
    //         //     path: '/dental-patient-waiting',
    //         //     name: 'Bệnh nhân đang chờ',
    //         //     permission: 'dental_patient_cases'
    //         // },
    //         // {
    //         //     path: '/doctor-current-dental',
    //         //     name: 'Ca đang làm',
    //         //     //permission: 'doctor_current_dental'
    //         // },
    //         // {
    //         //     path: '/doctor-completed-dental',
    //         //     name: 'Ca đã làm',
    //         //     //permission: 'doctor_completed_dental'
    //         // },
    //         // {
    //         //     path: '/doctor-salary-sheet',
    //         //     name: 'Bảng lương',
    //         //     //permission: 'doctor_doctor_salary_sheet'
    //         // }
    //     ]
    // },
    {
        path: '#',
        name: 'Thu chi',
        icon: <DollarOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/cash-receipt',
                name: 'Phiếu thu',
                permission: 'cash_receipt'
            },
            {
                path: '/payment-voucher',
                name: 'Phiếu chi',
                permission: 'payment_voucher'
            },
            {
                path: '/cash-book',
                name: 'Sổ quỹ',
                permission: 'cash_book'
            },
            // {
            //     path: '/daily-cash-reconciliation',
            //     name: 'Chốt quỹ hàng ngày',
            //     //permission: ''
            // },
            // {
            //     path: '/cash-flow-ledger',
            //     name: 'Quỹ lưu động',
            //     //permission: ''
            // }
        ]
    },
    {
        path: '/media',
        name: 'Media',
        icon: <FileImageOutlined className="sidebar-icon" />,
        permission: 'media'
    },
    // {
    //     path: '/media-library',
    //     name: 'Thư viện Media',
    //     icon: <FileImageOutlined className="sidebar-icon" />,
    //     permission: 'media_library'
    // },
    {
        path: '/voucher-list',
        name: 'Voucher',
        icon: <ShoppingOutlined className="sidebar-icon" />,
        permission: 'voucher'
    },
    {
        path: '#',
        name: 'Kho',
        icon: <ShopOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/warehouse',
                name: 'Danh sách kho',
                permission: 'product_warehouse'
            },
            {
                path: '/product-supplier',
                name: 'Nhà cung cấp',
                permission: 'product_supplier'
            },
            {
                path: '/product-group',
                name: 'Nhóm sản phẩm',
                permission: 'product_group'
            },
            {
                path: '/product-list',
                name: 'Sản phẩm',
                permission: 'product'
            },
            {
                path: '/receipt-import',
                name: 'Phiếu nhập',
                permission: 'product_receipt_import'
            },
            {
                path: '/receipt-export',
                name: 'Phiếu xuất',
                permission: 'product_receipt_export'
            },
            // {
            //     path: '/order',
            //     name: 'Quản lý đơn hàng',
            //     icon: <ShoppingCartOutlined className="sidebar-icon" />,
            //     permission: 'order'
            // }
        ]
    },
    {
        path: '#',
        name: 'Quản lý dự án',
        icon: <ProjectOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/project',
                name: 'Dự án',
                permission: 'project'
            },
            {
                path: '/project-based-job',
                name: 'Công việc',
                permission: 'project_based_job'
            }
        ]
    },
    {
        path: '#',
        name: 'Phân ca',
        icon: <FileTextOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/functional-area',
                name: 'Khu vực chức năng',
                permission: 'functional_area'
            },
            {
                path: '/functional',
                name: 'Chức năng của khu vực',
                permission: 'functional'
            },
            {
                path: '/jobs',
                name: 'Công việc',
                permission: 'jobs'
            },
            {
                path: '/shift-scheduling',
                name: 'Ca làm việc',
                permission: 'shift_scheduling'
            }
        ]
    },
    {
        path: '#',
        name: 'Thẻ khách hàng',
        icon: <IdcardOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/card-pending-customers?tab=pending',
                name: 'Khách hàng chờ làm thẻ',
                permission: 'card_pending_customers'
            },
            {
                path: '/card-release-tracking',
                name: 'Theo dõi phát hành thẻ',
                permission: 'card_release_tracking'
            },
            {
                path: '/card-issued-count',
                name: 'Số lượng thẻ được phát đi',
                permission: 'card_issued_count'
            },
            {
                path: '/card-type',
                name: 'Loại thẻ',
                permission: 'card_type'
            }
        ]
    },
    {
        path: '/posts',
        name: 'Bài viết',
        icon: <FormOutlined className="sidebar-icon" />,
        permission: 'posts'
    },
    {
        path: '/work-report',
        name: 'Báo cáo',
        icon: <FileDoneOutlined className="sidebar-icon" />,
        permission: 'work_report'
    },
    {
        path: '#',
        name: 'Hoạt động',
        icon: <HistoryOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/history',
                name: 'Lịch sử thay đổi',
                permission: 'history',
            },
            {
                path: '/view-phonenumber',
                name: 'Lịch sử xem số điện thoại',
                permission: 'view_phonenumber'
            }
        ]
    },
    {
        path: '/guide',
        name: 'Hướng dẫn sử dụng',
        icon: <QuestionCircleOutlined className="sidebar-icon" />,
        permission: 'guide'
    },
    {
        path: '#',
        name: 'Quay thưởng',
        icon: <GiftOutlined className="sidebar-icon" />,
        permission: null,
        children: [
            {
                path: '/reward-histories?tab=reward_current',
                name: 'Giải thưởng',
                permission: 'reward_spin'
            },
            {
                path: '/reward-config',
                name: 'Cấu hình',
                permission: 'reward_spin'
            },
            {
                path: '/reward-customer-has',
                name: 'Khách hàng đã quay',
                permission: 'reward_spin'
            }
        ]
    },
    {
        path: '/profile',
        name: 'Thông tin tài khoản',
        icon: <UserOutlined className="sidebar-icon" />,
        permission: null,
    }
];

export default menu;