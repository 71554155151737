import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Divider, Tag, Row, Col, Card, Descriptions, Typography, Space, Image, Collapse } from 'antd'
import { CalendarOutlined, FileImageOutlined, FolderOutlined, UserOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import { isValidDate } from '../../utils/helpers';
import ExpandableText from '../../components/ExpandableText';

const { Text } = Typography;
const { Panel } = Collapse;

class CustomerServiceConsultantList extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        var { currentItem } = this.props;

        return (
            <>
                {/* <Divider orientation="left" orientationMargin="0" className='mt-4 mb-3'>
                    <Tag color='#0b2b33'>5</Tag> Tư vấn trực tiếp
                </Divider> */}
                {
                    currentItem && currentItem?.direct_consultant.length ? (
                        <Row gutter={16} className='mb-3 row-customer-direct-consultant'>
                            {
                                currentItem?.direct_consultant.map((item, index) => {
                                    return (
                                        <Col key={index} xs={24} md={12} lg={8} className='box-item-service customer-info-data mb-3'>
                                            <Card size="small"
                                                title={<>{<CalendarOutlined />} Thời điểm tư vấn: {isValidDate(item.time_consultant)}</>}
                                                style={{ background: '#465fa324' }}
                                            >
                                                <Row gutter={16}>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Trạng thái">
                                                                {
                                                                    item?.status == 0 ? (
                                                                        <Tag color='#f5b22d'>Chưa trả kết quả</Tag>
                                                                    ) : null
                                                                }
                                                                {
                                                                    item?.status == 1 ? (
                                                                        <Tag color='green'>Đã trả kết quả</Tag>
                                                                    ) : null
                                                                }
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className='no-margin' />
                                                    </Col>
                                                </Row>

                                                <Row gutter={16}>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Ngày tạo">
                                                                {isValidDate(item.created_at)}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className='no-margin' />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Người tạo">
                                                                {item.created_user}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className='no-margin' />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Cập nhật lần cuối">
                                                                {item.user_update_result}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className='no-margin' />
                                                    </Col>
                                                </Row>
                                                <Row gutter={16}>
                                                    <Col xs={24} md={24}>
                                                        <Collapse
                                                            expandIconPosition="end"
                                                            ghost
                                                            defaultActiveKey={[]}
                                                        >
                                                            <Panel
                                                                header={<><span className="font-weight-500"><UserOutlined /> Nhân viên tư vấn</span></>}
                                                                key="1"
                                                            >
                                                                {
                                                                    Array.isArray(item?.consultant_direct_staff) && item?.consultant_direct_staff.length ? (
                                                                        <>
                                                                            {
                                                                                item?.consultant_direct_staff.map(staff => {
                                                                                    return (
                                                                                        <Tag>{staff.staff_consultant_direct}</Tag>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    ) : null
                                                                }
                                                            </Panel>
                                                        </Collapse>
                                                    </Col>
                                                </Row>
                                                <Row gutter={16} className='customer-service-box no-border'>
                                                    <Col xs={24} md={24}>
                                                        <Collapse
                                                            expandIconPosition="end"
                                                            ghost
                                                            defaultActiveKey={[]}
                                                        >
                                                            <Panel
                                                                header={<><span className="font-weight-500"><FileImageOutlined />Ảnh</span></>}
                                                                key="1"
                                                            >
                                                                {
                                                                    Array.isArray(item?.images) && item?.images.length ? (
                                                                        <Image.PreviewGroup>
                                                                            <Space wrap>
                                                                                {
                                                                                    item.images.map((item, index) => {
                                                                                        return (
                                                                                            <Image
                                                                                                key={index}
                                                                                                height={30}
                                                                                                width={30}
                                                                                                src={item?.src}
                                                                                                style={{
                                                                                                    border: '1px solid #dedede',
                                                                                                    objectFit: 'cover',
                                                                                                    objectPosition: 'center',
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Space>
                                                                        </Image.PreviewGroup>
                                                                    ) : null
                                                                }
                                                            </Panel>
                                                        </Collapse>
                                                        <Divider className='no-margin' />
                                                    </Col>
                                                </Row>
                                                <Row gutter={16} className='mt-2'>
                                                    <Col xs={24} md={24}>
                                                        <Text strong>Kết quả tư vấn: </Text>
                                                        <ExpandableText
                                                            text={item.result_content}
                                                            rows={1}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col >
                                    )
                                })
                            }
                        </Row >
                    ) : <Row gutter={16} justify='center'><div className='no-data'>Chưa có dữ liệu</div></Row>
                }
            </>
        )
    }
}


function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerServiceConsultantList' })(CustomerServiceConsultantList));
