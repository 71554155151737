import React, { useState, useRef, useEffect } from "react";
import { Typography } from "antd";

const { Paragraph } = Typography;

const ExpandableText = ({ text, rows = 2 }) => {
    const [expanded, setExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const contentRef = useRef(null);

    // Hàm đếm số từ trong text
    const countWords = (text) => {
        if (typeof text !== 'string' || !text.trim()) return 0;
        // Loại bỏ tất cả các thẻ HTML
        const plainText = text.replace(/<[^>]*>/g, '');
        // Đếm số từ
        return plainText.trim().split(/\s+/).length;
    };

    useEffect(() => {
        if (contentRef.current) {
            const { scrollHeight, clientHeight } = contentRef.current;
            setIsOverflowing(scrollHeight > clientHeight); // Kiểm tra nếu nội dung bị tràn
        }
    }, [text]);

    // Kiểm tra số từ có lớn hơn 10 không
    const isTextLongEnough = countWords(text) > 10;

    return (
        <div>
            <Paragraph
                ref={contentRef}
                style={{
                    marginBottom: 0,
                    maxHeight: !expanded ? `${rows * 1.5}em` : "none", // Giả định mỗi dòng khoảng 1.5em
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: !expanded ? rows : "none",
                    WebkitBoxOrient: "vertical",
                }}
            >
                <span dangerouslySetInnerHTML={{ __html: text }} />
            </Paragraph>

            {isTextLongEnough && isOverflowing && (
                <span
                    style={{ color: "#1890ff", cursor: "pointer", marginLeft: 5 }}
                    onClick={() => setExpanded(!expanded)}
                >
                    {expanded ? "Thu gọn" : "Xem thêm"}
                </span>
            )}
        </div>
    );
};

export default ExpandableText;