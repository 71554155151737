import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import imageCompression from "browser-image-compression";
import { Image, Modal, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { generateId } from "../utils/helpers";

const compressOptions = {
    maxSizeMB: 0.2,
    maxWidthOrHeight: 500,
    useWebWorker: true,
};

const { confirm } = Modal;

const ImageSelector = ({
    onChangeData = () => { },
    multiple = false,
    maxCount = 1,
    uploadText = "Chọn ảnh",
    defaultFileList = [],
}) => {
    const [fileList, setFileList] = useState(defaultFileList);

    useEffect(() => {
        const normalizeFileList = (list) => {
            return list.map((item) => ({
                manual_id: item.manual_id || generateId(),
                file: null,
                originalImage: item.src || null,
                compressedImage: item.small || null,
                originalFileName: "",
                isLoading: false,
            }));
        };
        setFileList(normalizeFileList(defaultFileList));
    }, [JSON.stringify(defaultFileList)]); // Chuyển defaultFileList thành chuỗi JSON để kiểm tra thay đổi thực sự, tránh vòng lặp vô hạn


    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const onDrop = useCallback(
        async (acceptedFiles, fileRejections) => {

            // Kiểm tra số lượng file hiện tại
            if (fileList.length >= maxCount) {
                Modal.error({
                    title: "Quá số lượng cho phép",
                    content: `Bạn chỉ có thể tải lên tối đa ${maxCount} ảnh.`,
                });
                return;
            }

            // Kiểm tra các tệp bị từ chối
            if (fileRejections.length > 0) {
                fileRejections.forEach(({ file, errors }) => {
                    errors.forEach((error) => {
                        if (error.code === "file-invalid-type") {
                            Modal.error({
                                title: "Tệp không hợp lệ",
                                content: `${file.name} không phải là ảnh. Vui lòng chọn tệp có định dạng ảnh.`,
                            });
                        }
                    });
                });
                return;
            }

            const newFiles = acceptedFiles.slice(0, maxCount - fileList.length);

            if (newFiles.length === 0) {
                return;
            }

            // Thêm trạng thái isLoading cho mỗi ảnh
            const filesWithLoading = newFiles.map((file, index) => ({
                manual_id: generateId(),
                file,
                originalImage: null,
                compressedImage: null,
                originalFileName: file.name.replace(/\.[^/.]+$/, ""),
                isLoading: true,
            }));

            setFileList((prev) => [...prev, ...filesWithLoading]);

            const processedFiles = await Promise.all(
                filesWithLoading.map(async (item) => {
                    const originalBase64 = await getBase64(item.file);
                    const compressedFile = await imageCompression(item.file, compressOptions);
                    const compressedBase64 = await getBase64(compressedFile);

                    return {
                        ...item,
                        originalImage: originalBase64,
                        compressedImage: compressedBase64,
                        isLoading: false, // Xử lý xong
                    };
                })
            );

            setFileList((prev) =>
                prev.map((item) => processedFiles.find((p) => p.manual_id === item.manual_id) || item)
            );
            const updatedFileList = [...fileList, ...processedFiles];
            onChangeData(updatedFileList, { action: "add" });
        },
        [fileList, maxCount, onChangeData]
    );

    const handleRemove = (manual_id) => {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá ảnh này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => {
                const updatedFiles = fileList.filter((item) => item.manual_id !== manual_id);
                setFileList(updatedFiles);
                onChangeData(updatedFiles, { action: "remove", removedId: manual_id });
            },
            onCancel() { },
        })
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/*': []
        },
        multiple,
    });

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                flexWrap: "wrap",
            }}
        >
            <Image.PreviewGroup>
                {fileList.map((item) => (
                    <div
                        key={item.manual_id}
                        style={{
                            position: "relative",
                            width: "55px",
                            height: "55px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {item.isLoading ? (
                            <Spin />
                        ) : (
                            <>
                                <Image
                                    src={item.originalImage}
                                    alt={item.originalFileName}
                                    style={{
                                        width: "55px",
                                        height: "55px",
                                        objectFit: "cover",
                                        objectPosition: "center",
                                        borderRadius: "4px"
                                    }}
                                />
                                <button
                                    style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background: "#fd050580",
                                        color: "#fff",
                                        border: "none",
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleRemove(item.manual_id)}
                                >
                                    ✕
                                </button>
                            </>
                        )}
                    </div>
                ))}
            </Image.PreviewGroup>

            {fileList.length < maxCount && (
                <div
                    {...getRootProps()}
                    style={{
                        border: "1px dashed #d9d9d9",
                        textAlign: "center",
                        cursor: "pointer",
                        width: "55px",
                        height: "55px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "4px",
                        borderRadius: "4px",
                    }}
                >
                    <input {...getInputProps()} />
                    <PlusOutlined />
                    <p style={{ fontSize: "10px", margin: 0 }}>{uploadText}</p>
                </div>
            )}
        </div>
    );
};

export default ImageSelector;