import api from '../../utils/api';
import {
    GET_LIST_MEDIA_LIBRARY,
    GEt_SPECIFIC_MEDIA_LIBRARY,
    CREATE_MEDIA_LIBRARY,
    UPDATE_MEDIA_LIBRARY,
    REMOVE_MEDIA_LIBRARY,
    GET_MEDIA_LIBRARY_CUSTOMER,
    GET_MEDIA_LIBRARY_CUSTOMER_SERVICE
} from './types';
import { NotificationManager } from 'react-notifications'

export const getListAll = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/media-library', { params: filter }).then(res => {
            //console.log(res)
            dispatch({
                type: GET_LIST_MEDIA_LIBRARY,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getSpecificMedia = id => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/media-library/${id}`).then(res => {
            dispatch({
                type: GEt_SPECIFIC_MEDIA_LIBRARY,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getMediaCustomer = customer_id => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/media-library/customer/${customer_id}`).then(res => {
            dispatch({
                type: GET_MEDIA_LIBRARY_CUSTOMER,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getMediaCustomerService = customer_service_id => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/media-library/customer-service/${customer_service_id}`).then(res => {
            dispatch({
                type: GET_MEDIA_LIBRARY_CUSTOMER_SERVICE,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createMedia = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/media-library/create', data).then(res => {
            dispatch({
                type: CREATE_MEDIA_LIBRARY,
                payload: res.data.data
            });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateMedia = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/media-library/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_MEDIA_LIBRARY,
                payload: res.data.data
            });
            NotificationManager.success("Update bản ghi thành công!")
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeMedia = ids => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/media-library', { data: { ids: ids } }).then(res => {
            dispatch({
                type: REMOVE_MEDIA_LIBRARY,
                payload: ids
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}