import api from '../../utils/api';
import {
    CUSTOMER_SERVICES_GET_LIST,
    CUSTOMER_SERVICES_GET_SPECIFIC,
    CUSTOMER_SERVICES_UPDATE_SPECIFIC,
    CUSTOMER_SERVICES_CREATE_NEW,
    CUSTOMER_SERVICES_REMOVE,
    CUSTOMER_SERVICES_GET_STATISTICAL,
    CUSTOMER_SERVICES,
    CUSTOMER_SERVICES_CREATE_EXAM_VISIT,
    CUSTOMER_SERVICES_UPDATE_EXAM_VISIT,
    CUSTOMER_SERVICES_REMOVE_EXAM_VISIT,
    CUSTOMER_SERVICES_GET_SPECIFIC_EXAM_VISIT,
    CUSTOMER_SERVICES_EXPORT_EXCEL
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListServices = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-services', { params: filter }).then(res => {
            dispatch({ type: CUSTOMER_SERVICES_GET_LIST, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificService = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer-services/${id}`).then(res => {
            dispatch({ type: CUSTOMER_SERVICES_GET_SPECIFIC, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getCustomerServices = (customer_id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer-services/customer/${customer_id}`).then(res => {
            dispatch({ type: CUSTOMER_SERVICES, payload: res.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createService = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer-services', data).then(res => {
            dispatch({ type: CUSTOMER_SERVICES_CREATE_NEW, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateService = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-services/${id}`, data).then(res => {
            dispatch({ type: CUSTOMER_SERVICES_UPDATE_SPECIFIC, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeService = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/customer-services`, { data: { ids: ids } }).then(res => {
            dispatch({ type: CUSTOMER_SERVICES_REMOVE, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getStatistical = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-services/statistical/total', { params: filter }).then(res => {
            //console.log(res.data)
            dispatch({
                type: CUSTOMER_SERVICES_GET_STATISTICAL,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

// Lấy chi tiết lần khám
export const getSpecificExamVisit = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer-services/exam-visit/${id}`).then(res => {
            dispatch({ type: CUSTOMER_SERVICES_GET_SPECIFIC_EXAM_VISIT, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

// Thêm lần khám
export const createExamVisit = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer-services/exam-visit/create', data).then(res => {
            dispatch({ type: CUSTOMER_SERVICES_CREATE_EXAM_VISIT, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

// Cập nhật lần khám
export const updateExamVisit = (exam_visit_id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-services/exam-visit/${exam_visit_id}`, data).then(res => {
            dispatch({ type: CUSTOMER_SERVICES_UPDATE_EXAM_VISIT, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

// Xóa lần khám
export const removeExamVisit = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/customer-services/exam-visit/delete`, { data: { ids: ids } }).then(res => {
            dispatch({ type: CUSTOMER_SERVICES_REMOVE_EXAM_VISIT, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const exportExcel = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/customer-services/export`, filter).then(res => {
            dispatch({ type: CUSTOMER_SERVICES_EXPORT_EXCEL, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            console.log(err.response);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    })
}