import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Divider, Tag, Button, Row, Col, Space, Alert, Typography, Card, Spin, Image, Collapse } from 'antd'
import { QRCode, ColorPicker } from 'antd-v5';
import { EditOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import ReactHtmlParser from 'react-html-parser';
import { ConvertcustomerGender, repeatPhoneNumber, isValidDate, ConvertCustomerCardStatus } from '../../utils/helpers';
import { isMobile } from 'react-device-detect';
import CustomerEditForm from './CustomerEditForm';
import ServicesInterestedList from './ServicesInterestedList';
import CustomerServicesList from './CustomerServicesList';
import CustomerMediaProfileDetail from '../media/CustomerMediaProfileDetail';
import CustomerMediaEditForm from './CustomerMediaEditForm';
import CustomerServiceConsultantList from './CustomerServiceConsultantList';
import CustomerTelesaleDetail from './CustomerTelesaleDetail';
import CustomerOrderDetail from './CustomerOrderDetail';
import NumberFormat from 'react-number-format';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';

import CustomerMedicalDetail from '../customer-medical/CustomerMedicalDetail';
import CustomerInteractionDetail from './CustomerInteractionDetail';
import FamilyRelationshipsList from './FamilyRelationshipsList';
import VoucherCustomerDetail from '../voucher/VoucherCustomerDetail';
import ScheduleCustomerDetail from '../customer-schedule/ScheduleCustomerDetail';
import PaymentHistory from '../../components/PaymentHistory';

const { Paragraph } = Typography;
const { Panel } = Collapse;

class CustomerDetail extends Component {
    constructor() {
        super();
        this.state = {
            isOpenEditForm: false,
            currentServiceProvided: null,
            isOpenServiceProvidedForm: false,
            isOpenMediaEditForm: false
        };
        this.scheduleFormRef = React.createRef();
        this.servicesInterestedFormRef = React.createRef();
        this.servicesSelectedFormRef = React.createRef();
        this.profilesFormRef = React.createRef();
        this.medicalFormRef = React.createRef();
        this.voucherFormRef = React.createRef();
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenEditForm(isOpenEditForm) {
        this.setState({ isOpenEditForm: isOpenEditForm });
    }

    handleOpenScheduleForm = () => {
        if (this.scheduleFormRef.current) {
            this.scheduleFormRef.current.openForm();
        }
    };

    handleOpenServicesInterestedForm = () => {
        if (this.servicesInterestedFormRef.current) {
            this.servicesInterestedFormRef.current.toggleOpenForm(true);
        }
    };

    handleOpenServicesSelectedForm = () => {
        if (this.servicesSelectedFormRef.current) {
            this.servicesSelectedFormRef.current.toggleOpenForm(true);
        }
    };

    handleOpenProfilesForm = () => {
        if (this.profilesFormRef.current) {
            this.profilesFormRef.current.openForm();
        }
    };

    handleOpenMedicalForm = () => {
        if (this.medicalFormRef.current) {
            this.medicalFormRef.current.openForm();
        }
    };

    handleOpenVoucherForm = () => {
        if (this.voucherFormRef.current) {
            this.voucherFormRef.current.openForm();
        }
    };

    render() {
        var { isOpenEditForm, isOpenMediaEditForm } = this.state;
        var { visible, currentItem, loadingDetail } = this.props;

        return (
            <>
                <Drawer
                    visible={visible}
                    title="Hồ sơ khách hàng"
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '80%'}
                    bodyStyle={{ paddingTop: 0 }}
                    extra={
                        checkPermission('customer', 'update') ? (
                            <Button type="primary" onClick={() => this.toggleOpenEditForm(true)} icon={<EditOutlined />}>Sửa thông tin khách hàng</Button>
                        ) : null
                    }
                >
                    {visible ? (
                        <div className='wrap-customer-detail'>
                            <Row gutter={16} className='list-action-button'>
                                <Col xs={24} md={24} className='mb-2'>
                                    <Space size={[8, 16]} wrap>
                                        <Button onClick={this.handleOpenScheduleForm} type="dashed" className='color-blue' disabled={!checkPermission('customer_schedule', 'create')}>Thêm lịch hẹn</Button>
                                        <Button onClick={this.handleOpenServicesInterestedForm} type='dashed' className='color-blue' disabled={!checkPermission('customer_services', 'create')}>Thêm dịch vụ quan tâm</Button>
                                        <Button onClick={this.handleOpenServicesSelectedForm} type="dashed" className='color-blue' disabled={!checkPermission('customer_services', 'create')}>Thêm dịch vụ chốt</Button>
                                        <Button onClick={this.handleOpenProfilesForm} type="dashed" className='color-blue' disabled={!checkPermission('customer', 'update_image_profile')}>Cập nhật hồ sơ</Button>
                                        <Button onClick={this.handleOpenMedicalForm} type="dashed" className='color-blue' disabled={!checkPermission('customer_medical', 'create')}>Cập nhật thông tin y tế</Button>
                                        <Button onClick={this.handleOpenVoucherForm} type="dashed" className='color-blue' disabled={!checkPermission('voucher', 'apply_customer')}>Thêm Voucher</Button>
                                    </Space>
                                </Col>
                            </Row>
                            <Spin tip="Loading..." spinning={loadingDetail}>
                                {/* <Divider orientation="left" orientationMargin="0" style={{ marginTop: 0 }}>
                                    <Tag color='#0b2b33'>1</Tag> Thông tin khách hàng
                                </Divider> */}

                                <Collapse
                                    ghost
                                    bordered={false}
                                    defaultActiveKey={['12']}
                                    expandIconPosition="end"
                                    className="customer-detail-collapse mt-3"
                                >
                                    <Panel
                                        header={<><Tag color='#0b2b33'>1</Tag> Thông tin khách hàng</>}
                                        key="12"
                                        forceRender
                                        className="customer-detail-collapse-panel"
                                    >
                                        <Row gutter={16}>
                                            <Col xs={24} md={12}>
                                                <Row gutter={16}>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Tên khách hàng">
                                                                {currentItem ? currentItem.full_name : null}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Mã khách hàng">
                                                                <Paragraph style={{ margin: 0 }} copyable={
                                                                    {
                                                                        text: currentItem ? currentItem.code : null,
                                                                        tooltips: false
                                                                    }
                                                                }>
                                                                    <Tag color='#1890ff'>{currentItem ? currentItem.code : null}</Tag>
                                                                </Paragraph>
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Số điện thoại">
                                                                {currentItem ? (
                                                                    <HandlePhoneNumber
                                                                        permission={checkPermission('customer', 'phonenumber')}
                                                                        inputPinCode={checkPermission('customer', 'input_pin_code')}
                                                                        customer_id={currentItem.id}
                                                                        hiddenPhoneNumber={repeatPhoneNumber(currentItem?.phone)}
                                                                        fullPhoneNumber={currentItem?.phone}
                                                                    />
                                                                ) : null}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Cơ sở">
                                                                {currentItem ? currentItem.branch_name : null}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Doanh số">
                                                                <Tag color='green'>
                                                                    <NumberFormat value={currentItem?.revenue} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                </Tag>
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Ưu đãi">
                                                                <Tag color='blue'>
                                                                    <NumberFormat value={currentItem?.total_discount} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                </Tag>
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Đã thanh toán">
                                                                <Tag color='cyan'>
                                                                    <NumberFormat value={currentItem?.total_amount_paid} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                </Tag>
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Còn nợ">
                                                                <Tag color='red'>
                                                                    <NumberFormat value={currentItem?.customer_debt} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                </Tag>
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    {/* <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Hình thức thanh toán">
                                                                Trả góp
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Col> */}
                                                </Row>
                                            </Col>

                                            <Col xs={24} md={12}>
                                                <Row gutter={16}>
                                                    {/* <Col xs={24} md={24}>
                                                <Descriptions size="small">
                                                    <Descriptions.Item label="Hạng khách hàng">
                                                        <Tag color="#f39c12">{currentItem ? currentItem.customer_class : null}</Tag>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <Divider className="no-margin" />
                                            </Col> */}
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Nguồn">
                                                                {currentItem ? currentItem.customer_source : null}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Mã số thẻ cũ">
                                                                {
                                                                    currentItem?.card_number ? (
                                                                        <Paragraph style={{ margin: 0 }} copyable={
                                                                            {
                                                                                text: currentItem ? currentItem.card_number : null,
                                                                                tooltips: false
                                                                            }
                                                                        }>
                                                                            {currentItem ? currentItem.card_number : null}
                                                                        </Paragraph>
                                                                    ) : null
                                                                }

                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Mã số thẻ mới">
                                                                {
                                                                    currentItem?.card_number_new ? (
                                                                        <Paragraph style={{ margin: 0 }} copyable={
                                                                            {
                                                                                text: currentItem ? currentItem.card_number_new : null,
                                                                                tooltips: false
                                                                            }
                                                                        }>
                                                                            {currentItem ? currentItem.card_number_new : null}
                                                                        </Paragraph>
                                                                    ) : null
                                                                }

                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Mã số sổ khám">
                                                                {
                                                                    currentItem?.medical_book_number ? (
                                                                        <Paragraph style={{ margin: 0 }} copyable={
                                                                            {
                                                                                text: currentItem ? currentItem.medical_book_number : null,
                                                                                tooltips: false
                                                                            }
                                                                        }>
                                                                            {currentItem ? currentItem.medical_book_number : null}
                                                                        </Paragraph>
                                                                    ) : null
                                                                }

                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Giới tính">
                                                                {currentItem ? ConvertcustomerGender(currentItem.gender) : null}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Năm sinh">
                                                                {currentItem?.birthday}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Trạng thái thẻ">
                                                                {currentItem ? ConvertCustomerCardStatus(currentItem?.card_status) : null}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Nhóm">
                                                                {currentItem ? currentItem.group_names : null}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Ngày tạo">
                                                                {isValidDate(currentItem.created_at)} bởi: {currentItem ? currentItem.created_user : null}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row gutter={16} className='mt-3'>
                                            <Col xs={24} md={24}>
                                                <Alert
                                                    message={<span style={{ fontWeight: '600' }}>Ghi chú</span>}
                                                    description={
                                                        <>
                                                            {currentItem ? ReactHtmlParser(currentItem.note) : null}

                                                            {Array.isArray(currentItem?.banner_ads) && currentItem?.banner_ads.length ? (
                                                                <>
                                                                    <Divider orientation="left" orientationMargin={0}>Banner quảng cáo</Divider>
                                                                    <Image.PreviewGroup>
                                                                        <Space>
                                                                            {
                                                                                currentItem?.banner_ads.map(item => {
                                                                                    return (
                                                                                        <Image
                                                                                            height={50}
                                                                                            width={50}
                                                                                            src={item.src}
                                                                                            style={{
                                                                                                border: '1px solid #dedede',
                                                                                                objectFit: 'cover',
                                                                                                objectPosition: 'center',
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Space>
                                                                    </Image.PreviewGroup>
                                                                </>
                                                            ) : null}
                                                        </>
                                                    }
                                                    type="info"
                                                    className='fix-alert-element'
                                                />
                                            </Col>
                                        </Row>
                                        {currentItem?.customer_media.length ? (
                                            <Row gutter={16} className='mt-3'>
                                                <Col xs={24} md={24}>
                                                    <Card
                                                        title="Ảnh tình trạng hiện tại"
                                                        size='small'
                                                        extra={
                                                            <>
                                                                {checkPermission('customer', 'update_media') ? (
                                                                    <a onClick={() => this.setState({ isOpenMediaEditForm: true })} type="link" key="list-vertical-edit">
                                                                        <EditOutlined /> Sửa
                                                                    </a>
                                                                ) : null}
                                                            </>
                                                        }
                                                    >
                                                        <Image.PreviewGroup>
                                                            <Space>
                                                                {
                                                                    currentItem?.customer_media.map((item, index) => {
                                                                        return (
                                                                            <Image
                                                                                key={index}
                                                                                height={80}
                                                                                width={80}
                                                                                src={item.source}
                                                                                style={{
                                                                                    border: '1px solid #dedede',
                                                                                    objectFit: 'cover',
                                                                                    objectPosition: 'center',
                                                                                }}
                                                                            />
                                                                        )
                                                                    })
                                                                }
                                                            </Space>
                                                        </Image.PreviewGroup>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        ) : null}
                                    </Panel>
                                    <Panel forceRender className="customer-detail-collapse-panel" header={<><Tag color='#0b2b33'>2</Tag> Telesale</>} key="1">
                                        <CustomerTelesaleDetail reloadData={() => this.props.reloadData()} currentItem={currentItem} />
                                    </Panel>
                                    <Panel forceRender className="customer-detail-collapse-panel" header={<><Tag color='#0b2b33'>3</Tag> Lịch hẹn</>} key="2">
                                        <ScheduleCustomerDetail ref={this.scheduleFormRef} currentCustomer={currentItem} />
                                    </Panel>
                                    <Panel forceRender className="customer-detail-collapse-panel" header={<><Tag color='#0b2b33'>4</Tag> Dịch vụ quan tâm</>} key="3">
                                        <ServicesInterestedList ref={this.servicesInterestedFormRef} currentItem={currentItem} reloadData={() => this.props.reloadData()} />
                                    </Panel>
                                    <Panel forceRender className="customer-detail-collapse-panel" header={<><Tag color='#0b2b33'>5</Tag> Tư vấn trực tiếp</>} key="4">
                                        <CustomerServiceConsultantList currentItem={currentItem} />
                                    </Panel>
                                    <Panel forceRender className="customer-detail-collapse-panel" header={<><Tag color='#0b2b33'>6</Tag> Dịch vụ đã chốt</>} key="5">
                                        <CustomerServicesList ref={this.servicesSelectedFormRef} currentItem={currentItem} reloadData={() => this.props.reloadData()} />
                                    </Panel>
                                    <Panel forceRender className="customer-detail-collapse-panel" header={<><Tag color='#0b2b33'>7</Tag> Hồ sơ</>} key="6">
                                        <CustomerMediaProfileDetail ref={this.profilesFormRef} currentCustomer={currentItem} />
                                    </Panel>
                                    <Panel forceRender className="customer-detail-collapse-panel" header={<><Tag color='#0b2b33'>8</Tag> Thông tin y tế</>} key="7">
                                        <CustomerMedicalDetail ref={this.medicalFormRef} currentCustomer={currentItem} />
                                    </Panel>
                                    <Panel forceRender className="customer-detail-collapse-panel" header={<><Tag color='#0b2b33'>9</Tag> Tương tác</>} key="8">
                                        <CustomerInteractionDetail currentItem={currentItem} />
                                    </Panel>
                                    <Panel forceRender className="customer-detail-collapse-panel" header={<><Tag color='#0b2b33'>10</Tag> Thông tin nhân khẩu</>} key="9">
                                        <FamilyRelationshipsList currentItem={currentItem} />
                                    </Panel>
                                    <Panel forceRender className="customer-detail-collapse-panel" header={<><Tag color='#0b2b33'>11</Tag> Lịch sử thanh toán</>} key="10">
                                        <PaymentHistory data={currentItem?.cashReceipts} />
                                    </Panel>
                                    <Panel forceRender className="customer-detail-collapse-panel" header={<><Tag color='#0b2b33'>12</Tag> Voucher</>} key="11">
                                        <VoucherCustomerDetail ref={this.voucherFormRef} currentCustomer={currentItem} />
                                    </Panel>
                                    <Panel forceRender className="customer-detail-collapse-panel" header={<><Tag color='#0b2b33'>13</Tag> Vật tư sử dụng</>} key="13">
                                        <CustomerOrderDetail currentItem={currentItem} reloadCustomerDetail={() => this.props.reloadData()} />
                                    </Panel>
                                    <Panel forceRender className="customer-detail-collapse-panel" header={<><Tag color='#0b2b33'>14</Tag> Chương trình affiliate</>} key="14">
                                        Chưa có dữ liệu
                                    </Panel>
                                    <Panel forceRender className="customer-detail-collapse-panel" header={<><Tag color='#0b2b33'>15</Tag> Thông tin bảo hiểm</>} key="15">
                                        Chưa có dữ liệu
                                    </Panel>
                                </Collapse>
                            </Spin>
                        </div>
                    ) : null}

                </Drawer>
                <CustomerEditForm
                    visible={isOpenEditForm}
                    currentItem={currentItem}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenEditForm(false)}
                />

                <CustomerMediaEditForm
                    visible={isOpenMediaEditForm}
                    currentItem={currentItem}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.setState({ isOpenMediaEditForm: false })}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser,
        branchActiveList: state.BranchReducer.branchActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerDetail' })(CustomerDetail));
