import api from '../../utils/api';
import {
    GET_LIST_CUSTOMER_GROUP,
    GET_SPECIFIC_CUSTOMER_GROUP,
    UPDATE_SPECIFIC_CUSTOMER_GROUP,
    CREATE_NEW_CUSTOMER_GROUP,
    REMOVE_CUSTOMER_GROUP,
    GET_CUSTOMER_GROUP_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListCustomerGroup = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-group', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_CUSTOMER_GROUP, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getCustomerGroupActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/customer-group/active', { params: filter }).then(res => {
            dispatch({ type: GET_CUSTOMER_GROUP_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificCustomerGroup = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/customer-group/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_CUSTOMER_GROUP, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createCustomerGroup = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/customer-group', data).then(res => {
            dispatch({ type: CREATE_NEW_CUSTOMER_GROUP, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateCustomerGroup = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/customer-group/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_CUSTOMER_GROUP, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeCustomerGroup = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/customer-group`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_CUSTOMER_GROUP, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
